<template>
  消息
  <PostCard ></PostCard>
</template>

<script setup>

import PostCard from "@/components/PostCard.vue";
import {onMounted, ref} from "vue";
import {getMyMessageUsingGet} from "@/api/friendsController";
import message from "@arco-design/web-vue/es/message";
import {map} from "lodash";
import {useStore} from "vuex";


const dataList = ref();
const store = useStore();

onMounted(async () =>{
   const res  = await getMyMessageUsingGet();
   if(res.data.code === 0){
       dataList.value = res.data.data
   }else{
       message.error("获取信息失败" + res.data.message);
   }
   console.log("data的值："+data.value.User)
})


</script>

<style scoped>

</style>