import {RouteRecordRaw} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import UserLoginPage from "@/views/user/UserLoginPage.vue";
import UserRegisterPage from "@/views/user/UserRegisterPage.vue";
import UserRankPage from "@/views/user/UserRankPage.vue";
import UserInfoPage from "@/views/user/UserInfoPage.vue";
import AddQuestionView from "@/views/question/AddQuestionView.vue";
import ManagerQuestionView from "@/views/question/ManagerQuestionView.vue";
import doQuestionView from "@/views/question/doQuestionView.vue";
import QuestionSubmitView from "@/views/question/QuestionSubmitView.vue";
import TeamViewPage from "@/views/team/TeamViewPage.vue";
import TeamRankPage from "@/views/team/TeamRankPage.vue";
import PostViewPage from "@/views/post/PostViewPage.vue";
import MyPostPage from "@/views/my/MyPostPage.vue";
import MyTeamPage from "@/views/my/MyTeamPage.vue";
import MyFriend from "@/views/my/MyFriend.vue";
import UserViewPage from "@/views/user/UserViewPage.vue";
import ChatRoom2 from "@/views/user/chat/ChatRoom2.vue";
import ChatRoom from "@/views/user/chat/ChatRoom.vue";
import MyMessage from "@/views/my/MyMessage.vue";
import QuestionStatistic from "@/views/statistic/QuestionStatistic.vue";
import ACCESS_ENUM from "@/access/accsessEnum";

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "主页",
        component: HomeView,
    },
    {
        path: "/admin",
        name: "管理员",
        component: HomeView,
        meta:{
            access:ACCESS_ENUM.ADMIN,
            hideMenu: true
        }
    },
    {
        path: "/question/question",
        name: "创建题目",
        component: AddQuestionView,
        meta:{
            access:ACCESS_ENUM.ADMIN
        }
    },
    {
        path: "/update/question",
        name: "更新题目",
        component: AddQuestionView,
        meta:{
            access:ACCESS_ENUM.ADMIN,
            hideMenu: true
        }
    },
    {
        path: "/do/question/:id",
        name: "做题页",
        component: doQuestionView,
        props:true,
        meta:{
            access:ACCESS_ENUM.USER,
            hideMenu: true
        }
    },
    {
        path: "/manager/question",
        name: "题目管理",
        component: ManagerQuestionView,
        meta:{
            access:ACCESS_ENUM.ADMIN
        }
    },
    {
        path: "/record/question_submit",
        name: "提交记录",
        component: QuestionSubmitView,
        meta:{
            access:ACCESS_ENUM.USER
        }
    },
    {
        path: "/rank",
        name: "个人排行榜",
        component: UserRankPage,
    },
    {
        path: "/team",
        name: "队伍广场",
        component: TeamViewPage,
    },
    {
        path: "/team_rank",
        name: "队伍排行榜",
        component: TeamRankPage,
    },
    {
        path: "/my/detail",
        name: "用户中心",
        component: UserInfoPage,
        meta: {
            access: ACCESS_ENUM.USER,
            hideMenu: true,
        },
    },
    {
        path: "/post",
        name: "帖子区",
        component: PostViewPage,
    },
    {
        path: "/my/post",
        name: "我的帖子",
        component: MyPostPage,
        meta: {
            hideMenu: true,
        },
    },
    {
        path: "/my/team",
        name: "我的队伍",
        component: MyTeamPage,
        meta: {
            hideMenu: true,
        },
    },
    {
        path: "/my/friend",
        name: "我的好友",
        component: MyFriend,
        // meta: {
        //     hideMenu: true,
        // },
    },
    {
        path: "/friend",
        name: "交友大厅",
        component: UserViewPage,
        meta:{
            hideMenu: true
        }
    },
    {
        path: "/user/login",
        name: "用户登录",
        component: UserLoginPage,
        meta:{
            hideMenu: true
        }
    },
    {
        path: "/user/register",
        name: "用户注册",
        component: UserRegisterPage,
        meta:{
            hideMenu: true
        }
    },
    {
        path: "/message",
        name: "消息中心",
        component: MyMessage,
        meta:{
            hideMenu: true
        }
    },
    {
        path: "/statistic",
        name: "统计查询",
        component: QuestionStatistic,
        meta:{
            access:ACCESS_ENUM.ADMIN
        }
    },
    {
        path: "/statistic",
        name: "统计查询",
        component: QuestionStatistic,
        meta:{
            access:ACCESS_ENUM.ADMIN
        }
    },

    {
        path: "/chat",
        name: "聊天室",
        component: ChatRoom,
        meta:{
            hideMenu: true
        }
    },
];