<template>
    <div class="appStatisticPage">
        <h2>热门应用统计</h2>
        <v-chart :option="AppCountOption" style="height: 300px" />
        <a-input-search
                :style="{ width: '320px' }"
                placeholder="输入 questionId"
                button-text="搜索"
                search-button
                @search="(value) => loadAppAnswerResultCount(value)"
        />

        <h2>应用结果统计</h2>
        <v-chart :option="AppAnswerResultOption" style="height: 300px" />
    </div>
</template>

<script setup lang="ts">
import {reactive, ref, watchEffect,onMounted,computed} from 'vue'
import {listUserByPageUsingPost} from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import AppCard from "@/components/AppCard.vue"
import VChart from "vue-echarts";
import "echarts";
import {getQuestionLanguageNumUsingGet, getQuestionSubmitNumUsingGet} from "@/api/questionController";

const AppCountOption = computed(() =>{
    return{
        xAxis: {
            type: 'category',
            data: questionSubmitList.value.map((item) => item.questionId),
            name: "题目Id"
        },
        yAxis: {
            type: 'value',
            name:"提交次数"
        },
        series: [
            {
                data: questionSubmitList.value.map((item) => item.submitNum),
                type: 'bar'
            }
        ]
    };
})

const AppAnswerResultOption = computed(() =>{
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: '题目语言分布',
                type: 'pie',
                radius: '50%',
                data: questionLanguageCountList.value.map((item) => {
                    return{
                        value:item.languageNum,
                        name:item.language
                    }
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
})


const questionSubmitList = ref<API.QuestionSubmitNum[]>([])

const questionLanguageCountList = ref<API.QuestionLanguageNum[]>([])

//调用接口获取应用数据
const loadAppCount = async () =>{
    const res = await getQuestionSubmitNumUsingGet();
    if(res.data.code === 0){
        questionSubmitList.value = res.data.data || [];
    }else{
        message.error("获取失败" + res.data.message)
    }
}

const loadAppAnswerResultCount = async (questionId:string) =>{
    if(!questionId){
        return;
    }
    const res = await getQuestionLanguageNumUsingGet({
        questionId:questionId as any,
    });
    if(res.data.code === 0){
        questionLanguageCountList.value = res.data.data || [];
    }else{
        message.error("获取失败" + res.data.message)
    }
}


// /**
//  * 执行搜索
//  */
// const doSearch = () => {
//   searchParam.value = {
//     ...initSearchParam,
//     ...formSearchParams.value,
//   };
// };

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() =>{
    loadAppAnswerResultCount("")
})

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() =>{
    loadAppCount()
})

</script>

<style scoped>
.searchBar {
    text-align: center;
    margin-bottom: 28px;
}

.list-demo-action-layout .image-area {
    width: 183px;
    height: 119px;
    overflow: hidden;
    border-radius: 2px;
}

.list-demo-action-layout .list-demo-item {
    padding: 20px 0;
    border-bottom: 1px solid var(--color-fill-3);
}

.list-demo-action-layout .image-area img {
    width: 100%;
}

.list-demo-action-layout .arco-list-item-action .arco-icon {
    margin: 0 4px;
}
</style>