// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** AgreeApply POST /api/friend/agree/${param0} */
export async function agreeApplyUsingPost(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.AgreeApplyUsingPOSTParams,
  options?: { [key: string]: any },
) {
  const { fromId: param0, ...queryParams } = params;
  return request<API.BaseResponseBoolean_>(`/api/friend/agree/${param0}`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** ApplyAddUser POST /api/friend/apply */
export async function applyAddUserUsingPost(
  body: API.FriendApplyRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/friend/apply', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** CountApply GET /api/friend/count */
export async function countApplyUsingGet(options?: { [key: string]: any }) {
  return request<API.BaseResponseLong_>('/api/friend/count', {
    method: 'GET',
    ...(options || {}),
  });
}

/** DeleteFriend POST /api/friend/delete/${param0} */
export async function deleteFriendUsingPost(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DeleteFriendUsingPOSTParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.BaseResponseBoolean_>(`/api/friend/delete/${param0}`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** DisagreeApply POST /api/friend/disagree/${param0} */
export async function disagreeApplyUsingPost(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DisagreeApplyUsingPOSTParams,
  options?: { [key: string]: any },
) {
  const { fromId: param0, ...queryParams } = params;
  return request<API.BaseResponseBoolean_>(`/api/friend/disagree/${param0}`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** getMyMessage GET /api/friend/get/message */
export async function getMyMessageUsingGet(options?: { [key: string]: any }) {
  return request<API.BaseResponseListMessageVO_>('/api/friend/get/message', {
    method: 'GET',
    ...(options || {}),
  });
}

/** sendMessage POST /api/friend/send */
export async function sendMessageUsingPost(
  body: API.SendMessageRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/friend/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** ShowFriendsApplyList GET /api/friend/show/apply */
export async function showFriendsApplyListUsingGet(options?: { [key: string]: any }) {
  return request<API.BaseResponseListFriendsVO_>('/api/friend/show/apply', {
    method: 'GET',
    ...(options || {}),
  });
}

/** ShowMySendList GET /api/friend/show/send */
export async function showMySendListUsingGet(options?: { [key: string]: any }) {
  return request<API.BaseResponseListFriendsVO_>('/api/friend/show/send', {
    method: 'GET',
    ...(options || {}),
  });
}
