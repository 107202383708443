<template>

    <a-card title="我的好友">
        <a-space style="margin-bottom: 30px">
            <icon-unordered-list size="32" @click="doFriends"/>
            <icon-message size="32" style="margin-left: 1050px" @click="doMessage"/>
        </a-space>
        <template v-if="dataList === []">
            暂无好友<a-empty/>
        </template>
        <template v-for="userVO in dataList" >
            <UserCard :userVO="userVO"></UserCard>
        </template>

    </a-card>

    <template>
        <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel">
            <template #title>
                我的消息
            </template>
        </a-modal>
    </template>

    <template>
        <a-modal v-model:visible="visible2" @ok="handleOk" @cancel="handleCancel">
            <template #title>
                好友管理
            </template>
            <div>You can customize modal body text by the current situation. This modal will be closed immediately once you press the OK button.</div>
        </a-modal>
    </template>

</template>

<script setup lang="ts">
    import UserCard from '@/components/UserCard'
    import {ref,onMounted} from 'vue'
    import {
        addPostUsingPost,
        listMyPostVoByPageUsingPost,
        listPostByPageUsingPost,
        listPostVoByPageUsingPost
    } from "@/api/postController";
    import PostVO = API.PostVO;
    import message from "@arco-design/web-vue/es/message";
    import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
    import {useStore} from "vuex";
    import {addTeamUsingPost} from "@/api/teamController";
    import MdEditor from "@/components/MdEditor.vue";
    import {showMyFriendsUsingGet} from "@/api/userController";
    import {getMyMessageUsingGet} from "@/api/friendsController";
    import PostCard from "@/components/PostCard.vue";

    const dataList = ref<API.UserVO[]>([])
    const loadData = async () =>{
        const res = await showMyFriendsUsingGet({});
        if(res.data.code === 0){
            dataList.value = res.data.data || [];
        }else{
            message.error("获取失败");
        }
    }

    onMounted(() =>{
        loadData();
    })

    const visible = ref(false);
    const visible2 = ref(false);

    const handleOk = () => {
        visible.value = false;
        visible2.value = false
    };
    const handleCancel = () => {
        visible.value = false;
        visible2.value = false;
    }

    const messageList = ref();
    const doMessage = async () =>{
        visible.value = true;
        const res  = await getMyMessageUsingGet();

        if(res.data.code === 0){
            messageList.value = res.data.data
        }else{
            message.error("获取信息失败" + res.data.message);
        }
    }

    const doFriends = () =>{
        visible2.value = true;
    }

</script>

<style scoped>

</style>